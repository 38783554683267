<template>
  <v-app>
    <app-navigation-drawer />
    <app-navbar />
    <router-view v-slot="{ Component, route }">
      <v-slide-x-transition hide-on-leave leave-absolute>
        <component :is="Component" :key="route" />
      </v-slide-x-transition>
    </router-view>
    <app-footer />
  </v-app>
</template>

<script setup lang="ts">
import { useSeoMeta } from '@unhead/vue'

const drawer = ref(false)
provide('drawer', drawer)

useHead({
  titleTemplate(title) {
    return title === 'Psycho-Medical' ? title : `${title} | Psycho-Medical`
  },
})

useSeoMeta({
  description:
    'Jesteśmy Poradnią Zdrowia Psychicznego, dedykowaną udzielaniu wsparcia osobom przeżywającym kryzys psychiczny i emocjonalny.',
  ogType: 'website',
  twitterCard: 'summary_large_image',
})
</script>
