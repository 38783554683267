<template>
  <app-sierotki v-slot="{ fix }">
    <p
      class="font-weight-medium"
      v-html="
        fix(`
        W momencie, gdy odczuwasz trudności emocjonalne, psychiczne lub zmagasz się z problemami,
        które wpływają na Twoje codzienne funkcjonowanie, naturalne może być pytanie:
        do kogo udać się najpierw – psychologa czy psychiatry?
    `)
      "
    />

    <h3 v-text="fix('Kim jest psycholog?')" />
    <p
      v-html="
        fix(`
        Psycholog to specjalista zajmujący się badaniem, diagnozowaniem i leczeniem problemów emocjonalnych i psychicznych,
        które niekoniecznie wynikają z zaburzeń psychicznych. Psychologowie kliniczni i terapeuci stosują techniki psychoterapii,
        aby pomóc pacjentom zrozumieć siebie, rozwiązywać problemy interpersonalne,
        radzić sobie z trudnymi emocjami oraz zmieniać szkodliwe wzorce myślenia i zachowania.
        Nie przepisują leków, lecz ich praca skupia się na rozmowie, wsparciu emocjonalnym i terapii.
    `)
      "
    />

    <h3 v-text="fix('Kim jest psychiatra?')" />
    <p
      v-html="
        fix(`
        Psychiatra to lekarz medycyny, specjalizujący się w diagnozowaniu i leczeniu zaburzeń psychicznych, który może przepisywać leki psychotropowe.
        Do psychiatry warto zgłosić się, gdy podejrzewasz, że Twoje trudności mogą mieć podłoże biologiczne,
        takie jak depresja, schizofrenia, zaburzenia afektywne dwubiegunowe czy zaburzenia lękowe wymagające farmakoterapii.
      `)
      "
    />

    <h3 v-text="fix('Kiedy wybrać psychologa?')" />
    <ol>
      <li
        v-html="
          fix(
            `Gdy odczuwasz stres, lęk, smutek lub inne trudne emocje, ale nie jesteś pewien, czy to już zaburzenie psychiczne.`,
          )
        "
      />
      <li
        v-html="
          fix(
            `Kiedy potrzebujesz wsparcia w radzeniu sobie z codziennymi trudnościami, problemami w relacjach, pracą czy życiem osobistym.`,
          )
        "
      />
      <li
        v-html="
          fix(
            `Jeśli szukasz kogoś do rozmowy, analizy swoich zachowań i emocji oraz pracy nad nimi w ramach psychoterapii.`,
          )
        "
      />
    </ol>

    <h3 v-text="fix('Kiedy wybrać psychiatrę?')" />
    <ol>
      <li
        v-html="
          fix(
            `Gdy Twoje problemy emocjonalne trwają dłużej i zaczynają znacząco wpływać na funkcjonowanie w życiu codziennym.`,
          )
        "
      />
      <li
        v-html="
          fix(
            `Jeśli doświadczasz objawów, które mogą sugerować poważne zaburzenia psychiczne, takich jak halucynacje, myśli samobójcze, ekstremalne wahania nastroju czy trudności z funkcjonowaniem.`,
          )
        "
      />
      <li v-html="fix(`Gdy podejrzewasz, że potrzebujesz farmakoterapii.`)" />
    </ol>

    <h3 v-text="fix('Współpraca obu specjalistów')" />
    <p
      v-html="
        fix(`
        W wielu przypadkach współpraca psychologa i psychiatry daje najlepsze rezultaty.
        Farmakoterapia w połączeniu z psychoterapią może być kluczem do lepszego zdrowia psychicznego.
        Jeśli nie jesteś pewien, od którego specjalisty zacząć, warto umówić się na konsultację z psychologiem.
        Psycholog może ocenić Twoje potrzeby i, jeśli to konieczne, skierować Cię do psychiatry.
    `)
      "
    />

    <h3 v-text="fix('Podsumowanie')" />
    <p
      v-html="
        fix(`
        Decyzja o tym, czy udać się do psychologa, czy psychiatry, zależy od charakteru Twoich problemów i objawów, których doświadczasz.
        Warto pamiętać, że nie musisz podejmować tej decyzji sam.
        Nasi specjaliści są tutaj, aby Ci pomóc w zrozumieniu Twoich trudności i wskazać najlepszą drogę do zdrowia psychicznego.
    `)
      "
    />
  </app-sierotki>
</template>

<script lang="ts" setup>
import thumbnail from '@/assets/blog/2024-01-03-psycholog-czy-psychiatra.jpg'

defineOptions({
  metadata: {
    title: 'Psycholog czy psychiatra – do kogo najpierw?',
    date: '2024-01-03',
    tags: ['porady'],
    readTime: 4,
    thumbnail,
    description:
      'W momencie, gdy odczuwasz trudności emocjonalne, psychiczne lub zmagasz się z problemami, które wpływają na Twoje codzienne funkcjonowanie, naturalne może być pytanie: do kogo udać się najpierw – psychologa czy psychiatry?',
  },
})

defineExpose({
  metadata: {
    title: 'Psycholog czy psychiatra – do kogo najpierw?',
    date: '2024-01-03',
    tags: ['porady'],
    readTime: 4,
    thumbnail,
    description:
      'W momencie, gdy odczuwasz trudności emocjonalne, psychiczne lub zmagasz się z problemami, które wpływają na Twoje codzienne funkcjonowanie, naturalne może być pytanie: do kogo udać się najpierw – psychologa czy psychiatry?',
  },
})

useSeoMeta({
  title: () => 'Psycholog czy psychiatra – do kogo najpierw?',
  ogTitle: () => 'Psycholog czy psychiatra – do kogo najpierw?',
  description: () =>
    'W momencie, gdy odczuwasz trudności emocjonalne, psychiczne lub zmagasz się z problemami, które wpływają na Twoje codzienne funkcjonowanie, naturalne może być pytanie: do kogo udać się najpierw – psychologa czy psychiatry?',
  ogDescription: () =>
    'W momencie, gdy odczuwasz trudności emocjonalne, psychiczne lub zmagasz się z problemami, które wpływają na Twoje codzienne funkcjonowanie, naturalne może być pytanie: do kogo udać się najpierw – psychologa czy psychiatry?',
  ogImage: () => thumbnail,
})

definePage({
  meta: {
    layout: 'blog-post',
  },
})
</script>
