<template>
  <app-sierotki v-slot="{ fix }">
    <p
      v-html="
        fix(
          `Przez wiele lat ADHD było postrzegane jako zaburzenie typowe dla dzieci i młodzieży. Jednak obecnie wiemy, że objawy ADHD mogą utrzymywać się również w dorosłości, wpływając na codzienne funkcjonowanie w pracy, w życiu społecznym i w związkach. Dla wielu dorosłych diagnoza ADHD jest momentem przełomowym, który pozwala im zrozumieć przyczyny swoich trudności, które wcześniej mogły być interpretowane jako brak dyscypliny, niedojrzałość lub lenistwo.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `Dorośli z ADHD często doświadczają trudności w planowaniu i organizacji, co może skutkować problemami w pracy oraz wywoływać napięcia w relacjach osobistych. Trudności te mogą prowadzić do spadku poczucia własnej wartości oraz frustracji. Co więcej, osoby dorosłe z ADHD często zmagają się z dodatkowymi wyzwaniami, takimi jak objawy depresji lub lęku, które są reakcją na trudności w codziennym funkcjonowaniu.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `Mimo że diagnoza ADHD w dorosłości niesie ze sobą wiele korzyści, budzi także kontrowersje. Niektórzy specjaliści zastanawiają się, czy ADHD jest rzeczywiście trwałym zaburzeniem u dorosłych, czy też objawy te mogą być skutkiem stylu życia lub innych problemów psychicznych. Niemniej jednak dla osób, które otrzymały diagnozę ADHD, jest to szansa na zrozumienie siebie i rozpoczęcie leczenia, które obejmuje zarówno psychoterapię, jak i odpowiednie wsparcie farmakologiczne.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `Diagnoza ADHD to szansa na poprawę jakości życia, ale wymaga zaakceptowania, że jest to wyzwanie, z którym można sobie radzić. Dzięki świadomości problemu oraz wsparciu specjalistycznemu wiele osób z ADHD może poprawić swoje funkcjonowanie i jakość życia. Dla wielu z nich diagnoza jest pierwszym krokiem na drodze do lepszego zrozumienia siebie i swoich potrzeb, co przynosi im ulgę i daje nadzieję na bardziej harmonijną przyszłość.`,
        )
      "
    />
  </app-sierotki>
</template>

<script lang="ts" setup>
import thumbnail from '@/assets/blog/2024-07-10-kontrowersje-wokoll-adhd-u-doroslych.jpg'

defineOptions({
  metadata: {
    title: 'Kontrowersje wokół ADHD u dorosłych – jak diagnoza zmienia życie?',
    date: '2024-07-10',
    tags: ['porady', 'adhd'],
    readTime: 4,
    thumbnail,
    description:
      'Przez wiele lat ADHD było postrzegane jako zaburzenie typowe dla dzieci i młodzieży. Jednak obecnie wiemy, że objawy ADHD mogą utrzymywać się również w dorosłości, wpływając na codzienne funkcjonowanie w pracy, w życiu społecznym i w związkach.',
  },
})

defineExpose({
  metadata: {
    title: 'Kontrowersje wokół ADHD u dorosłych – jak diagnoza zmienia życie?',
    date: '2024-07-10',
    tags: ['porady', 'adhd'],
    readTime: 4,
    thumbnail,
    description:
      'Przez wiele lat ADHD było postrzegane jako zaburzenie typowe dla dzieci i młodzieży. Jednak obecnie wiemy, że objawy ADHD mogą utrzymywać się również w dorosłości, wpływając na codzienne funkcjonowanie w pracy, w życiu społecznym i w związkach.',
  },
})

useSeoMeta({
  title: () =>
    'Kontrowersje wokół ADHD u dorosłych – jak diagnoza zmienia życie?',
  ogTitle: () =>
    'Kontrowersje wokół ADHD u dorosłych – jak diagnoza zmienia życie?',
  description: () =>
    'Przez wiele lat ADHD było postrzegane jako zaburzenie typowe dla dzieci i młodzieży. Jednak obecnie wiemy, że objawy ADHD mogą utrzymywać się również w dorosłości, wpływając na codzienne funkcjonowanie w pracy, w życiu społecznym i w związkach.',
  ogDescription: () =>
    'Przez wiele lat ADHD było postrzegane jako zaburzenie typowe dla dzieci i młodzieży. Jednak obecnie wiemy, że objawy ADHD mogą utrzymywać się również w dorosłości, wpływając na codzienne funkcjonowanie w pracy, w życiu społecznym i w związkach.',
  ogImage: () => thumbnail,
})

definePage({
  meta: {
    layout: 'blog-post',
  },
})
</script>
