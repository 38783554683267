<template>
  <app-sierotki v-slot="{ fix }">
    <p
      class="font-weight-medium"
      v-html="
        fix(`
        Leki psychiatryczne są ważnym elementem leczenia wielu zaburzeń psychicznych,
        w tym depresji, zaburzeń lękowych, schizofrenii i zaburzeń afektywnych dwubiegunowych.
        Chociaż mogą one znacząco poprawić jakość życia osób cierpiących, to jak każde leki, mogą powodować efekty uboczne.
        Warto zatem wiedzieć, czego możemy się spodziewać przyjmując tego typu terapię.
    `)
      "
    />

    <h3 v-html="fix('Najczęstsze efekty uboczne leków psychotropowych')" />
    <h4 v-html="fix('1. Zmiany masy ciała')" />
    <p
      v-html="
        fix(`
        Wiele leków psychiatrycznych może wpływać na wagę ciała.
        Niektóre antydepresanty i stabilizatory nastroju mogą powodować przyrost masy ciała, podczas gdy inne leki,
        takie jak niektóre leki przeciwpsychotyczne, mogą prowadzić do utraty wagi.
      `)
      "
    />

    <h4 v-html="fix('2. Zmiany nastroju i napędu')" />
    <p
      v-html="
        fix(`
        Paradoksalnie, niektóre leki przeciwdepresyjne mogą początkowo zwiększyć uczucie lęku,
        bezsenności lub niepokoju, zanim zaczną działać terapeutycznie.
      `)
      "
    />

    <h4 v-html="fix('3. Problemy seksualne')" />
    <p
      v-html="
        fix(`
        Antydepresanty, zwłaszcza z klasy SSRI (selektywne inhibitory wychwytu zwrotnego serotoniny),
        mogą negatywnie wpływać na libido, zdolność do osiągnięcia orgazmu lub erekcji.
      `)
      "
    />

    <h4 v-html="fix('4. Suchość w ustach i zaparcia')" />
    <p
      v-html="
        fix(`
        Leki przeciwpsychotyczne i niektóre leki przeciwdepresyjne mogą zmniejszać wydzielanie śliny,
        co prowadzi do suchości w ustach oraz problemów trawiennych takich jak zaparcia.
      `)
      "
    />

    <h4 v-html="fix('5. Zawroty głowy i problemy z widzeniem')" />
    <p
      v-html="
        fix(`
        Niektóre leki mogą wpływać na ciśnienie krwi lub akomodację oczu, powodując zawroty głowy,
        szczególnie przy szybkim wstawaniu, oraz problemy z ostrością widzenia.
      `)
      "
    />

    <h4 v-html="fix('6. Problemy Kardiologiczne')" />
    <p
      v-html="
        fix(`
        Niektóre leki przeciwpsychotyczne, stabilizatory nastroju i niektóre leki przeciwdepresyjne mogą wpływać
        na rytm serca lub prowadzić do zmian w elektrokardiogramie (EKG).
      `)
      "
    />

    <h4 v-html="fix('7. Zaburzenia Metaboliczne')" />
    <p
      v-html="
        fix(`
        Przyjmowanie niektórych leków antypsychotycznych może zwiększać ryzyko rozwoju cukrzycy typu 2 lub dyslipidemii.
      `)
      "
    />

    <h4 v-html="fix('8. Zaburzenia Neurologiczne')" />
    <p
      v-html="
        fix(`
        Długotrwałe stosowanie leków przeciwpsychotycznych może prowadzić do dyskinez późnych,
        czyli niekontrolowanych ruchów mięśni, zwłaszcza w okolicy twarzy.
      `)
      "
    />

    <h4 v-html="fix('9. Zwiększone Ryzyko Krwawienia')" />
    <p
      v-html="
        fix(`
        Niektóre antydepresanty mogą wpływać na krzepnięcie krwi,
        co zwiększa ryzyko krwawień, szczególnie w połączeniu z innymi lekami wpływającymi na krzepliwość krwi.
      `)
      "
    />

    <h3 v-html="fix(`Jak zarządzać efektami ubocznymi?`)" />
    <h4 v-html="fix('1. Komunikacja z lekarzem')" />
    <p
      v-html="
        fix(`
        Najważniejszym działaniem jest regularna komunikacja z lekarzem psychiatrą.
        Zgłaszanie wszystkich objawów niepożądanych jest kluczowe,
        by lekarz mógł odpowiednio dostosować leczenie.
      `)
      "
    />

    <h4 v-html="fix('2. Stopniowe dawkowanie')" />
    <p
      v-html="
        fix(`
        Często lekarze zalecają rozpoczęcie leczenia od małych dawek, stopniowo je zwiększając.
        To pozwala organizmowi na adaptację i może zmniejszyć ryzyko efektów ubocznych.
      `)
      "
    />

    <h4 v-html="fix('3. Zmiany w stylu życia')" />
    <p
      v-html="
        fix(`
        Aktywność fizyczna, odpowiednia dieta oraz techniki relaksacyjne mogą pomóc
        zarządzać niektórymi efektami ubocznymi, jak przyrost masy ciała czy problemy z energią.
      `)
      "
    />

    <h4 v-html="fix('4. Leki łagodzące efekty uboczne')" />
    <p
      v-html="
        fix(`
        W niektórych przypadkach, lekarz może przepisać dodatkowe leki, które pomogą złagodzić efekty uboczne.
      `)
      "
    />

    <h3 v-html="fix('Podsumowanie')" />
    <p
      class="mt-7"
      v-html="
        fix(`
        Efekty uboczne leków psychiatrycznych mogą być uciążliwe, ale ważne jest, by nie przerywać leczenia na własną rękę.
        Współpraca z lekarzem i monitorowanie objawów pozwala na efektywniejsze i bezpieczniejsze leczenie.
        Pamiętaj, że korzyści płynące z terapii często przewyższają niedogodności związane z efektami ubocznymi.
      `)
      "
    />
    <p
      v-html="
        fix(`
        Pamiętaj, że każdy przypadek jest inny i wymienione wyżej informacje nie zastępują profesjonalnej porady medycznej.
        Jeśli masz obawy dotyczące leków, które przyjmujesz, skonsultuj się z lekarzem.
      `)
      "
    />
  </app-sierotki>
</template>

<script lang="ts" setup>
import thumbnail from '@/assets/blog/2024-04-20-objawy-niepozadane-lekow-psychiatrycznych.jpg'

defineOptions({
  metadata: {
    title: 'Objawy niepożądane leków psychiatrycznych – czego się spodziewać?',
    date: '2024-04-20',
    tags: ['porady'],
    readTime: 5,
    thumbnail,
    description:
      'Leki psychiatryczne są ważnym elementem leczenia wielu zaburzeń psychicznych, w tym depresji, zaburzeń lękowych, schizofrenii i zaburzeń afektywnych dwubiegunowych. Chociaż mogą one znacząco poprawić jakość życia osób cierpiących, to jak każde leki, mogą powodować efekty uboczne. Warto zatem wiedzieć, czego możemy się spodziewać przyjmując tego typu terapię.',
  },
})

defineExpose({
  metadata: {
    title: 'Objawy niepożądane leków psychiatrycznych – czego się spodziewać?',
    date: '2024-04-20',
    tags: ['porady'],
    readTime: 5,
    thumbnail,
    description:
      'Leki psychiatryczne są ważnym elementem leczenia wielu zaburzeń psychicznych, w tym depresji, zaburzeń lękowych, schizofrenii i zaburzeń afektywnych dwubiegunowych. Chociaż mogą one znacząco poprawić jakość życia osób cierpiących, to jak każde leki, mogą powodować efekty uboczne. Warto zatem wiedzieć, czego możemy się spodziewać przyjmując tego typu terapię.',
  },
})

useSeoMeta({
  title: () =>
    'Objawy niepożądane leków psychiatrycznych – czego się spodziewać?',
  ogTitle: () =>
    'Objawy niepożądane leków psychiatrycznych – czego się spodziewać?',
  description: () =>
    'Leki psychiatryczne są ważnym elementem leczenia wielu zaburzeń psychicznych, w tym depresji, zaburzeń lękowych, schizofrenii i zaburzeń afektywnych dwubiegunowych. Chociaż mogą one znacząco poprawić jakość życia osób cierpiących, to jak każde leki, mogą powodować efekty uboczne. Warto zatem wiedzieć, czego możemy się spodziewać przyjmując tego typu terapię.',
  ogDescription: () =>
    'Leki psychiatryczne są ważnym elementem leczenia wielu zaburzeń psychicznych, w tym depresji, zaburzeń lękowych, schizofrenii i zaburzeń afektywnych dwubiegunowych. Chociaż mogą one znacząco poprawić jakość życia osób cierpiących, to jak każde leki, mogą powodować efekty uboczne. Warto zatem wiedzieć, czego możemy się spodziewać przyjmując tego typu terapię.',
  ogImage: () => thumbnail,
})

definePage({
  meta: {
    layout: 'blog-post',
  },
})
</script>
